import React from 'react'
import GalleryCard from './GalleryCard'
import Pagination from 'react-js-pagination'

const GalleryContent = ({ galleries, pagination, setPage }) => {
    const { current_page, per_page, total } = pagination
    return (
        <section className='blog-area ptb-50'>
            <div className='container py-5'>
                <div className='row'>
                    {galleries.map((gallery) => {
                        return (
                            
                                <GalleryCard
                                    key={gallery.id}
                                    post={gallery}
                                />
                        )
                    })}
                    <Pagination
                        activePage={current_page ? current_page : 0}
                        itemsCountPerPage={per_page ? per_page : 0}
                        totalItemsCount={total ? total : 0}
                        onChange={(pageNumber) => {
                            setPage(pageNumber)
                        }}
                        pageRangeDisplayed={4}
                        itemClass='page-item'
                        linkClass='page-link'
                    />
                </div>
            </div>
        </section>
    )
}

export default GalleryContent