import React, { useState, useEffect } from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import GalleryContent from '../components/Gallery/GalleryContent'
import { navigate } from 'gatsby'
import { getGalleries } from '../api/gallery'

const Gallery = ({ location }) => {
    const [galleries, setGalleries] = useState(null)
    const [page, setPage] = useState(1)
    const [pagination, setPagination] = useState(null)

    const params = new URLSearchParams(location.search)
    const category = params.get('category')

    useEffect(() => {
        (async () => {
            try {
                setPagination(null)
                setGalleries(null)
                const data = await getGalleries(category, page)
                setPagination(data)
                setGalleries(data.data)
            } catch (error) {
                setPagination(null)
                setPage(1)
                setGalleries(null)
                navigate('/404')
            }
        })()
    }, [category, page])

    if (!galleries) return null

    return (
        <Layout title="Galería">
            <PageBanner
                pageTitle='Galería'
                homePageText='Inicio'
                homePageUrl='/'
                activePageText='Galería'
            />
            <GalleryContent
                galleries={galleries}
                pagination={pagination}
                setPage={setPage}
            />
        </Layout>
    )
}

export default Gallery
